<template>
  <div class="base">
    <div >
      <h1 :class="`${clubVersion === 'v2' ? `font-v2 default-font-${defaultFont}` : ''}`">Configurações Gerais</h1>
      <div class="line"></div>
      <Item
        title="Avaliação de Cursos"
        :value="rating_home"
        @update="update"
        param="rating_home"
        descricao="Se ativado, a avaliação dos cursos será mostrado a todos os alunos independente se for baixa."
      >
        <template v-slot:icon>
          <avaliationIcon />
        </template>
      </Item>
      <Item
        title="Comentários"
        :value="coment_lesson"
        @update="update"
        param="coment_lesson"
        descricao="Os usuários poderão comentar nas Aulas."
      >
        <template v-slot:icon>
          <commentsIcon />
        </template>
        <template v-slot:sub>
          <div v-if="coment_lesson">
            <SubItem
              title="Moderação de Comentários"
              :value="comment_review"
              @update="update"
              param="comment_review"
              descricao="Se essa opção estiver ativa, os comentários deverão passar por uma aprovação do administrador."
            >
              <template v-slot:icon>
                <commentsIcon />
              </template>
            </SubItem>
            <SubItem
              title="Desabilitar comentários nos cursos"
              :disabledSwitch="true"
              param="disable_comments_courses"
              descricao="Selecione os cursos que deseja desabilitar os comentários."
            >
              <template v-slot:icon>
                <commentsIcon />
              </template>
              <template v-slot:sub>
                <b-form-group
                  class="label-custom"
                  label="Cursos"
                  label-for="name"
                >
                  <span>
                    <multiselect
                      ref="multiselectRef"
                      v-model="disable_comments_courses"
                      @scroll="handleScroll($event)"
                      @input="updateDisableCommentsCourses"
                      class="multiselect_courses"
                      placeholder="Cursos"
                      selectedLabel=""
                      deselectLabel=""
                      tagPlaceholder=""
                      selectLabel=""
                      track-by="id"
                      label="title"
                      :multiple="true"
                      :taggable="false"
                      :options="courses"
                    />
                  </span>
                </b-form-group>
              </template>
            </SubItem>
          </div>
        </template>
      </Item>      
      <Item
        title="Ajuda do sistema"
        :value="helper_data"
        @update="update"
        param="helper_data"
        descricao="Se ativado, os alunos poderão enviar mensagens com duvidas."
      >
        <template v-slot:icon>
          <helpIcon />
        </template>
        <template v-slot:sub>
          <div v-if="helper_data">
            <SubItem
              title="Sistema de ajuda externo"
              :value="external_help"
              @update="update"
              param="external_help"
              descricao="Se ativado, você podera colocar um link externo."
            >
              <template v-slot:icon>
                <helpIcon />
              </template>
              <template v-slot:sub>
                <b-form-input
                  v-if="external_help"
                  v-model="external_help_link"
                  type="text"
                  placeholder="Seu link aqui!"
                  class="inputSub"
                ></b-form-input>
                <span
                  v-if="external_help"
                  @click="saveText('external_help_link')"
                  style="cursor: pointer; text-decoration: underline"
                  >Salvar</span
                >
              </template>
            </SubItem>
          </div>
        </template>
      </Item>
      <Item
        title="Idioma"
        :value="null"
        param="pais"
        descricao="Com base no pais selecionado será validado o idioma do aluno ao entrar na plataforma"
      >
        <template v-slot:icon>
          <domainIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3" style="height: 7vh">
            <Locales />
          </div>
        </template>
      </Item>

      <Item
        title="Limite de usuários simultâneos na conta"
        :value="limit_devices_per_member"
        @update="update"
        param="limit_devices_per_member"
        descricao="Define o limite de usuários logados ao mesmo tempo na conta."
      >
        <template v-slot:icon>
          <helpIcon />
        </template>
        <template v-slot:sub>
          <div v-if="limit_devices_per_member">
            <b-form-input
              v-model="number_limit_devices_per_member"
              @change="updateNumberLimitAluno"
              style="width: 50%"
              variant="secondary"
              type="range"
              min="1"
              max="5"
              step="1"
            ></b-form-input>
            <div>
              <span
                >Limite: {{ number_limit_devices_per_member }}
                {{
                  number_limit_devices_per_member === 1 ? 'usuário' : 'usuários'
                }}</span
              >
            </div>
          </div>
        </template>
      </Item>
      <Item
        title="Apenas proprietários podem visualizar o Crisp Chat"
        v-if="access_level === 'owner'"
        :value="crisp_owner"
        @update="update"
        param="crisp_owner"
        descricao="Se ativado, somente você poderá visualizar o Crisp do Greenn"
      >
        <template v-slot:icon>
          <chatIcon />
        </template>
      </Item>
      <Item
        title="Adicionar JivoChat"
        :value="jivochat"
        @update="update"
        param="jivochat"
        descricao="Se ativado, terá um chat para seus alunos se comunicar com você"
      >
        <template v-slot:icon>
          <chatIcon />
        </template>
        <template v-slot:sub>
          <div v-if="jivochat">
            <b-form-input
              v-model="id_jivochat"
              type="text"
              placeholder="ID JivoChat"
              class="inputSub"
            ></b-form-input>
            <span
              @click="saveText('id_jivochat')"
              style="cursor: pointer; text-decoration: underline"
              >Salvar</span
            >
          </div>
        </template>
      </Item>
      <Item
        title="Adicionar Crisp Chat"
        :value="crisp"
        @update="update"
        param="crisp"
        descricao="Se ativado, terá um chat Crisp para seus alunos se comunicar com você"
      >
        <template v-slot:icon>
          <chatIcon />
        </template>
        <template v-slot:sub>
          <div v-if="crisp">
            <b-form-input
              v-model="id_crisp"
              type="text"
              placeholder="ID Crisp"
              class="inputSub"
            ></b-form-input>
            <span
              @click="saveText('id_crisp')"
              style="cursor: pointer; text-decoration: underline"
              >Salvar</span
            >
          </div>
        </template>
      </Item>
      <Item
        title="Adicionar Bitrix"
        :value="bitrix"
        @update="update"
        param="bitrix"
        descricao="Se essa opção estiver ativa tera um chat para seus alunos, basta copiar os dados que estão depois de https://cdn.bitrix24.com.br/"
      >
        <template v-slot:icon>
          <chatIcon />
        </template>
        <template v-slot:sub>
          <div v-if="bitrix">
            <b-form-input
              v-model="id_bitrix"
              type="text"
              placeholder="Ex: aa12345678"
              class="inputSub"
            ></b-form-input>
            <span
              @click="saveText('id_bitrix')"
              style="cursor: pointer; text-decoration: underline"
              >Salvar</span
            >
          </div>
        </template>
      </Item>
      <Item
        title="Testar arquivo protegido"
        :value="null"
        param="protegido"
        descricao="Selecionar um Arquivo"
      >
        <template v-slot:icon>
          <docIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <BaseButton @click="showModalUploadTeste" variant="link-info"
              >Clique aqui e selecione</BaseButton
            >
          </div>
        </template>
      </Item>
      <Item
        title="Tamanho da fonte Arquivo Protegido"
        :value="null"
        param="font"
        descricao=""
      >
        <template v-slot:icon>
          <hamburgIcon />
        </template>
        <template v-slot:sub>
          <div>
            <b-form-input
              v-model="protected_font_size"
              @change="updateFontSize"
              style="width: 50%"
              variant="secondary"
              type="range"
              min="0"
              max="50"
              step="1"
            ></b-form-input>
            <div>
              <span> Tamanho da Fonte: {{ protected_font_size }}</span>
            </div>
          </div>
        </template>
      </Item>
      <Item
        title="Domínio/Subdomínio"
        :value="null"
        param="comain"
        descricao="(SeuSite.com ou campanha.seusite.com.br), Depois de cadastrar aqui você terá as instruções de apontamento..."
      >
        <template v-slot:icon>
          <domainIcon />
        </template>
        <template v-slot:sub>
          <div class="mt-3">
            <BaseButton @click="openModalConfigDominio" variant="link-info"
              >Configurar Domínio</BaseButton
            >
          </div>
        </template>
      </Item>
      <Item
        title="Termos de uso"
        :value="termsOfUseActivated"
        @update="update"
        param="termsOfUseActivated"
        descricao="Apresente os termos de uso, sempre que um novo membro entrar."
      >
        <template v-slot:icon>
          <medalIcon />
        </template>
        <template v-slot:sub>
          <div v-if="termsOfUseActivated" class="mt-3">
            <BaseButton @click="openModalConfigTermos" variant="link-info"
              >Configurar Termos</BaseButton
            >
          </div>
        </template>
      </Item>
      <Item
        title="Porcentagem de conclusão da aula"
        :value="limit_percetage_lesson"
        @update="update"
        param="limit_percetage_lesson"
        descricao="Se ativado, o usuário devera concluir uma determina porcentagem da aula."
      >
        <template v-slot:icon>
          <percentageIcon />
        </template>
        <template v-slot:sub>
          <div v-if="limit_percetage_lesson">
            <b-form-input
              v-model="number_limit_percetage_lesson"
              @change="updateLimitPercentageLessonAluno"
              style="width: 50%"
              variant="secondary"
              type="range"
              min="0"
              max="100"
              step="5"
            ></b-form-input>
            <div>
              <span>
                Porcentagem necessaria para concluir a aula:
                {{ number_limit_percetage_lesson }} %</span
              >
            </div>
          </div>
        </template>
      </Item>
      <Item
        title="Concluir a aula automaticamente"
        :value="complete_lessson_next"
        @update="update"
        param="complete_lessson_next"
        descricao="Se ativado, assim que o usuario clicar em proximo ja ira concluir a aula."
      >
        <template v-slot:icon>
          <playIcon />
        </template>
        <template v-slot:sub>
          <div v-if="complete_lessson_next"></div>
        </template>
      </Item>
    </div>
    <ModalUpload />
    <ModalDominio />
    <ModalTermosConfig />
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import MetaService from '@/services/resources/MetaService';
import CourseService from '@/services/resources/CourseService';
import avaliationIcon from './icons/avaliation.vue';
import helpIcon from './icons/help.vue';
import ModalUpload from '@/components/ModalUpload.vue';
import ModalDominio from '@/components/configs/ModalDominio.vue';
import ModalTermosConfig from '@/components/ModalTermosConfig.vue';
import docIcon from './icons/doc.vue';
import hamburgIcon from './icons/hamburg.vue';
import domainIcon from './icons/domain.vue';
import medalIcon from './icons/medal.vue';
import percentageIcon from './icons/percentage.vue';
import playIcon from './icons/play.vue';
import Locales from './Locales.vue';
import Multiselect from 'vue-multiselect';
const serviceMeta = MetaService.build();
const serviceCourse = CourseService.build();

export default {
  components: {
    docIcon,
    hamburgIcon,
    domainIcon,
    medalIcon,
    playIcon,
    Locales,
    percentageIcon,
    avaliationIcon,
    helpIcon,
    ModalUpload,
    ModalDominio,
    ModalTermosConfig,
    Item: () => import('./Item.vue'),
    SubItem: () => import('./SubItem.vue'),
    commentsIcon: () => import('./icons/comments.vue'),
    chatIcon: () => import('./icons/chat.vue'),   
    Multiselect,
  },
  data() {
    return {
      rating_home: false,
      coment_lesson: false,
      comment_review: false,
      helper_data: false,
      theater_mode: false,
      external_help: false,
      limit_devices_per_member: false,
      jivochat: false,
      crisp_owner: false,
      crisp: false,
      bitrix: false,
      termsOfUseActivated: false,
      limit_percetage_lesson: false,
      complete_lessson_next: false,
      disable_comments_courses: false,
      number_limit_devices_per_member: 1,
      id_jivochat: '',
      id_crisp: '',
      id_bitrix: '',
      protected_font_size: 40,
      number_limit_percetage_lesson: 1,
      external_help_link: '',
      access_level: Cookies.get('access_level'),
      courses: [],
      loading: false,
      currentPage: 1,
      lastPage: null
    };
  },
  computed: {
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion || 'v1';
    },
    defaultFont() {
      return this.$store.state.metas.metas.default_font;
    }
  },
  methods: {
    async getKeys(key) {
      var keys = [];
      if (key) {
        keys = [key];
      } else {
        keys = [
          'rating_home',
          'coment_lesson',
          'comment_review',
          'helper_data',
          'theater_mode',
          'external_help',
          'external_help_link',
          'limit_devices_per_member',
          'jivochat',
          'crisp_owner',
          'crisp',
          'bitrix',
          'id_jivochat',
          'id_crisp',
          'id_bitrix',
          'termsOfUseActivated',
          'limit_percetage_lesson',
          'number_limit_percetage_lesson',
          'complete_lessson_next',
          'disable_comments_courses',
          'number_limit_devices_per_member',
          'protected_font_size',
        ];
      }
      var result = '';
      for (let i = 0; i < keys.length; i++) {
        if (keys.length - 1 == i) {
          result += `keys[]=${keys[i]}`;
        } else {
          result += `keys[]=${keys[i]}&`;
        }
      }
      let resp = await this.$store.dispatch("fetchMetas", keys);
        if (resp.rating_home !== undefined) {
          if (resp.rating_home === null || resp.rating_home === 'on') {
            this.rating_home = true;
          } else {
            this.rating_home = false;
          }
        }
        if (resp.coment_lesson !== undefined) {
          if (resp.coment_lesson === 'off') {
            this.coment_lesson = false;
          } else {
            this.coment_lesson = true;
          }
        }
        if (resp.comment_review !== undefined) {
          if (resp.comment_review === 'off') {
            this.comment_review = false;
          } else {
            this.comment_review = true;
          }
        }
        if (resp.helper_data !== undefined) {
          if (resp.helper_data === 'off') {
            this.helper_data = false;
          } else {
            this.helper_data = true;
          }
        }
        if (resp.theater_mode !== undefined) {
          if (resp.theater_mode === 'off') {
            this.theater_mode = false;
          } else {
            this.theater_mode = true;
          }
        }
        if (resp.external_help !== undefined) {
          if (resp.external_help === 'on') {
            this.external_help = true;
          } else {
            this.external_help = false;
          }
        }
        if (resp.external_help_link !== undefined) {
          if (resp.external_help_link) {
            this.external_help_link = resp.external_help_link;
          }
        }
        if (resp.limit_devices_per_member !== undefined) {
          if (
            resp.limit_devices_per_member === null ||
            resp.limit_devices_per_member === 'off'
          ) {
            this.limit_devices_per_member = false;
          } else {
            this.limit_devices_per_member = true;
          }
        }
        if (resp.number_limit_devices_per_member !== undefined) {
          if (resp.number_limit_devices_per_member !== null) {
            this.number_limit_devices_per_member =
              resp.number_limit_devices_per_member;
          } else {
            this.updateNumberLimitAluno(1);
          }
        }
        if (resp.jivochat !== undefined) {
          if (resp.jivochat === null || resp.jivochat === 'off') {
            this.jivochat = false;
          } else {
            this.jivochat = true;
          }
        }
        if (resp.id_jivochat !== undefined) {
          if (resp.id_jivochat !== null) {
            this.id_jivochat = resp.id_jivochat;
          }
        }
        if (resp.crisp_owner !== undefined) {
          if (resp.crisp_owner === null || resp.crisp_owner === 'off') {
            this.crisp_owner = false;
          } else {
            this.crisp_owner = true;
          }
        }
        if (resp.crisp !== undefined) {
          if (resp.crisp === null || resp.crisp === 'off') {
            this.crisp = false;
          } else {
            this.crisp = true;
          }
        }
        if (resp.id_crisp !== undefined) {
          if (resp.id_crisp !== null) {
            this.id_crisp = resp.id_crisp;
          }
        }
        if (resp.bitrix !== undefined) {
          if (resp.bitrix === null || resp.bitrix === 'off') {
            this.bitrix = false;
          } else {
            this.bitrix = true;
          }
        }
        if (resp.id_bitrix !== undefined) {
          if (resp.id_bitrix !== null) {
            this.id_bitrix = resp.id_bitrix.replace(
              'https://cdn.bitrix24.com.br/',
              '',
            );
          }
        }
        if (resp.termsOfUseActivated !== undefined) {
          if (resp.termsOfUseActivated === 'on') {
            this.termsOfUseActivated = true;
          } else {
            this.termsOfUseActivated = false;
          }
        }
        if (resp.limit_percetage_lesson !== undefined) {
          if (
            resp.limit_percetage_lesson === null ||
            resp.limit_percetage_lesson === 'off'
          ) {
            this.limit_percetage_lesson = false;
          } else {
            this.limit_percetage_lesson = true;
          }
        }
        if (resp.number_limit_percetage_lesson !== undefined) {
          if (resp.number_limit_percetage_lesson !== null) {
            this.number_limit_percetage_lesson = parseFloat(
              resp.number_limit_percetage_lesson,
            );
          } else {
            this.updateLimitPercentageLessonAluno(1);
          }
        }
        if (resp.complete_lessson_next !== undefined) {
          if (
            resp.complete_lessson_next === null ||
            resp.complete_lessson_next === 'off'
          ) {
            this.complete_lessson_next = false;
          } else {
            this.complete_lessson_next = true;
          }
        }
        if (
          resp.disable_comments_courses &&
          typeof resp.disable_comments_courses === 'string'
        ) {
          this.disable_comments_courses = JSON.parse(
            resp.disable_comments_courses,
          );
        }
        if (resp.number_limit_devices_per_member !== undefined) {
          if (resp.number_limit_devices_per_member !== null) {
            this.number_limit_devices_per_member = parseFloat(
              resp.number_limit_devices_per_member,
            );
          } else {
            this.updateNumberLimitAluno(1);
          }
        }
        if (resp.protected_font_size !== undefined) {
          if (
            resp.protected_font_size === null ||
            resp.protected_font_size === ''
          ) {
            this.protected_font_size = 40;
          } else {
            this.protected_font_size = parseFloat(resp.protected_font_size);
          }
        }
        if (this.$refs.locale) {
          this.$refs.locale.fetchCountry();
        }
    },
    mensageUpdate() {
      this.$bvToast.toast('Configuração atualizada com sucesso!', {
        title: 'Atualizado!',
        variant: 'success',
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    update(item) {
      let data = {
        id: item[1],
        value: item[0] ? 'on' : 'off',
      };
      serviceMeta.postID(data).then(() => {
        this.$store.dispatch(`updateMetas`, data);
        this.mensageUpdate();
        this.getKeys(item[1]);
        if (item[1] === 'helper_data') {
          this.$root.$emit('helper-updated');
        }
      });
    },
    saveText(text) {
      if (text === 'id_jivochat') {
        let data = {
          id: 'id_jivochat',
          value: this.id_jivochat,
        };
        serviceMeta.postID(data).then(() => {
          this.$store.dispatch(`updateMetas`, data);
          this.mensageUpdate();
          this.getKeys('id_jivochat');
        });
      }
      if (text === 'id_crisp') {
        let data = {
          id: 'id_crisp',
          value: this.id_crisp,
        };
        serviceMeta.postID(data).then(() => {
          this.$store.dispatch(`updateMetas`, data);
          this.mensageUpdate();
          this.getKeys('id_crisp');
        });
      }
      if (text === 'external_help_link') {
        let data = {
          id: 'external_help_link',
          value: this.external_help_link,
        };
        serviceMeta.postID(data).then(() => {
          this.$store.dispatch(`updateMetas`, data);
          this.mensageUpdate();
          this.getKeys('external_help_link');
        });
      }
      if (text === 'id_bitrix') {
        let data = {
          id: 'id_bitrix',
          value: this.id_bitrix,
        };
        serviceMeta.postID(data).then(() => {
          this.$store.dispatch(`updateMetas`, data);
          this.mensageUpdate();
          this.getKeys('id_bitrix');
        });
      }
    },
    updateFontSize(number) {
      var data = {
        id: 'protected_font_size',
        value: number,
      };
      serviceMeta.postID(data).then(() => {
        this.$store.dispatch(`updateMetas`, data);
        this.mensageUpdate();
      });
    },
    updateLimitPercentageLessonAluno(number) {
      let data = {
        id: 'number_limit_percetage_lesson',
        value: number,
      };
      serviceMeta.postID(data).then(() => {
        this.$store.dispatch(`updateMetas`, data);
        this.mensageUpdate();
      });
    },
    updateNumberLimitAluno(number) {
      let data = {
        id: 'number_limit_devices_per_member',
        value: number,
      };
      serviceMeta.postID(data).then(() => {
        this.$store.dispatch(`updateMetas`, data);
        this.mensageUpdate();
      });
    },
    updateDisableCommentsCourses() {
      var data = {
        id: 'disable_comments_courses',
        value: JSON.stringify(this.disable_comments_courses),
      };
      serviceMeta.postID(data).then(() => {
        this.$store.dispatch(`updateMetas`, data);
        this.mensageUpdate();
      });
    },
    showModalUploadTeste() {
      this.$root.$emit('pdfteste');
      this.$root.$emit('bv::show::modal', 'modal-upload', '#btnShow');
    },
    openModalConfigDominio() {
      this.$root.$emit('bv::show::modal', 'modal-dominio', '#btnShow');
    },
    openModalConfigTermos() {
      this.$root.$emit('bv::show::modal', 'modal-config-termos', '#btnShow');
    },
    getAllCourses() {
      this.loading = true;
      serviceCourse
        .search('page='+this.currentPage)
        .then((resp) => {
          this.lastPage = resp.last_page;
          resp.data.forEach((x) => {
            this.courses.push({ id: x.id, title: x.title });
          });
        })
        .catch((err) => console.error(err))
        .finally(() => {
          const multiselectDiv = this.$refs.multiselectRef.$el.querySelector('.multiselect__content');

            if (multiselectDiv) {     
              multiselectDiv.addEventListener('scroll', (event) => this.handleScroll(event));
            }
          this.loading = false;
        });
    },
    handleScroll(event) {
      const multiselectElement = event.target;
      if (
        multiselectElement.scrollHeight - multiselectElement.scrollTop <= multiselectElement.clientHeight &&
        !this.loading &&
        this.currentPage < this.lastPage
      ) {
        this.currentPage++;
        this.getAllCourses();
      }
    }
  },
  mounted() {
    this.getAllCourses();
    this.getKeys();
    this.$root.$on('midiapdfteste', (data) => {
      axios({
        url:
          process.env.VUE_APP_API_HOST + `/media/${parseInt(data.id)}/preview`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: Cookies.get('auth_greennCourse'),
        },
      }).then((response) => {
        var fileURL = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/pdf' }),
        );
        const fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'Arquivo de Teste');
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    });
  },
};
</script>

<style lang="scss" scoped>
.item {
  width: 100%;
  display: grid;
  grid-template-columns: 30px 1fr 50px;
  margin-bottom: 20px;
  h6 {
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-bottom: 12px;
  }
  span {
    font-weight: 400;
    font-size: 13px;
    color: #81858e;
    margin: 0;
  }
}

.inputSub {
  height: 40px;
  border: 1px solid #ededf0 !important;
  border-radius: 5px;
  font-weight: 400;
  font-size: 13px;
  color: #81858e;
  width: 40%;
  margin-top: 10px;
}

.base {
  width: calc(100% - 50px);
  padding-right: 50px;
}

h1 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: var(--fontcolor);
  margin-bottom: 25px;
}

.line {
  width: 100%;
  height: 1px;
  background: #ededf0;
  margin-bottom: 25px;
}

.multiselect_courses .multiselect__tags {
  height: 100% !important;
  max-height: 200px;
}
.font-v2 {
  font-family: Montserrat Alternates;
  font-size: 24px;
  font-weight: 600;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--fontcolor);
}
</style>
