<template>
  <div>
    <b-modal id="modal-dominio" hide-footer size="xl" @show="getAllDomains">
      <div class="allSpaceListDomain">
        <div class="flexbtnConfigDominio">
          <div>
            <p class="titleModalDominio">Meus Domínios</p>
            <p class="titleModalDominio2">Crie e Configure seus Domínios</p>
          </div>
          <button class="btnConfigDominio" @click="openNew">
            Adicionar um Domínio
          </button>
        </div>
        <div class="flexConfigDominioSub">
          <p class="subtitleModalDominio2">
            {{ totalDomain }} Domínios encontrados
          </p>
        </div>
        <div class="animationOpacity2">
          <div class="spaceTable">
            <div class="header">
              <div>Domínios</div>
              <div>Apontamento</div>
              <div>Status SSL</div>
              <div>Status</div>
              <!-- <div>Modificado em</div> -->
              <div>Ações</div>
            </div>
            <div class="itens">
              <div
                v-for="(item, index) in AllDominios"
                :key="item.id"
                class="spaceItens"
              >
                <div>
                  <div class="txt">{{ item.domain }}</div>
                </div>
                <div>
                  <div class="txt" v-if="item.redirect_status === 'waiting'">
                    Aguardando
                  </div>
                  <div class="txt" v-if="item.redirect_status === 'ready'">
                    Pronto
                  </div>
                </div>
                <div>
                  <div class="txt" v-if="item.ssl_status === 'waiting'">
                    Aguardando
                  </div>
                  <div class="txt" v-if="item.ssl_status === 'generated'">
                    Gerado
                  </div>
                  <div class="txt" v-if="item.ssl_status === 'ready'">
                    Pronto
                  </div>
                </div>
                <div>
                  <div class="txt" v-if="item.status === -5">
                    Tentou por mais de 1 mês
                  </div>
                  <div class="txt" v-if="item.status === -4">
                    Não é um dominio
                  </div>
                  <div class="txt" v-if="item.status === -3">
                    Dominio interno
                  </div>
                  <div class="txt" v-if="item.status === -2">
                    Caracteres especiais
                  </div>
                  <div class="txt" v-if="item.status === -1">
                    Faltando argumentos
                  </div>
                  <div class="txt" v-if="item.status === 0">Aguardando</div>
                  <div class="txt" v-if="item.status === 1">
                    Dominio não propagado
                  </div>
                  <div class="txt" v-if="item.status === 2">
                    Erro ao gerar o certificado
                  </div>
                  <div class="txt" v-if="item.status === 3">
                    Erro ao gerar o Virtual Host
                  </div>
                  <div class="txt" v-if="item.status === 4">
                    24 horas pra validar certificado
                  </div>
                  <div class="txt" v-if="item.status === 6">Domínio OK</div>
                </div>
                <!-- <div>
                        <div class="txt">{{ item.updated_at | moment("DD/MM/YYYY HH:mm") }}</div>
                    </div> -->
                <div class="actions">
                  <div
                    class="editar"
                    v-if="index !== 0"
                    v-on:click="toFirstDomain(item.id)"
                  >
                    Tornar Principal
                  </div>
                  <div class="deletar" v-on:click="deletarDomain(item.id)">
                    Deletar
                  </div>
                </div>
              </div>
            </div>
            <div class="paginationDomain" v-if="lastPageDomain !== 1">
              <div class="btnPage" @click="toPage('prev')">Anterior</div>
              <div class="btnPage" @click="toPage('next')">Próximo</div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <div class="allSpaceModalDominioFundo" :class="{ hide: !openNewDomain }">
      <div class="allSpaceModalDominio">
        <div class="spaceClose" @click="closeNew">
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18 6L6 18"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 6L18 18"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <p class="titleModalDominio">Configurar Domínio</p>
        <div v-if="pageDomain === 1">
          <p class="subtitleModalDominio">
            (SeuSite.com ou campanha.seusite.com.br), Depois de cadastrar aqui
            você terá as instruções de apontamento...
          </p>
          <div class="spaceInputModalDominio">
            <b-form-input
              id="inputNewDomain"
              class="inputModalDominio"
              v-model="createNewDomain"
              placeholder="Seu Domínio"
              :state="domainValidation"
            ></b-form-input>
            <b-form-invalid-feedback id="inputNewDomain">
              Caracteres especiais não são permitidos
            </b-form-invalid-feedback>
          </div>

          <!-- <div class="spaceStatusModalDominio">
              <div class="flexStatus">
                <div>
                  <p class="textStatusModalDominio">Status de Apontamento</p>
                  <div class="txt">Aguardando</div>

                </div>
                <div>
                  <p class="textStatusModalDominio">Status de SSL</p>
                  <div class="txt">Aguardando</div>
                </div>
              </div>
            </div> -->
          <div class="spaceBtnModalDominio">
            <button @click="createDomain" class="btnConfigDominio2">
              Salvar
            </button>
          </div>
        </div>
        <div v-if="pageDomain === 2">
          <p class="textDomain divColor">
            Seu Domínio/Subdomínio Foi Salvo, Agora Você Precisa fazer Os
            Apontamentos para funcionar Corretamente:
          </p>
          <div>
            <p class="textDomain">
              <span class="subtitleTextDomain">1.</span> Acesse o painel que o
              seu domínio está hospedado (Ex: Registro.Br, Godaddy, hostgator,
              etc)
            </p>
            <p class="textDomain">
              <span class="subtitleTextDomain">2.</span> Entre na configuração
              DNS do seu domínio. (Essa etapa poderá variar de acordo com o
              serviço de hospedagem).
            </p>
            <p class="textDomain">
              <span class="subtitleTextDomain">3.</span> Você vai adicionar 1
              entrada dentro do seu serviço de Dominio(registro.br por ex)
              direcionando para Nosso site.
            </p>
            <div class="textDomain">
              Faça um apontamento do tipo CNAME para:
            </div>
            <p class="textDomain">
              <span class="subtitleTextDomain mt-2">
                external.greenn.club
              </span>
            </p>
            <p class="textDomain">
              <span class="subtitleTextDomain">4.</span> Tendo o campos
              preenchido com a informação acima, é só Salvar o novo apontamento
              e pronto!
            </p>
          </div>
          <div class="spaceBtnModalDominio">
            <button @click="fecharModalDomain" class="btnConfigDominio2">
              Ok, Entendi
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DomainService from "@/services/resources/DomainService";
const serviceDomain = DomainService.build();

// Utils
import notify from "@/services/libs/notificacao";

export default {
  data() {
    return {
      dominioUrl: "",
      openNewDomain: false,
      totalDomain: 0,
      AllDominios: [],
      pageDomain: 1,
      AllDominiosFirst: [],
      currentPageDomain: 1,
      lastPageDomain: 1,
      createNewDomain: "",
    };
  },
  computed: {
    domainValidation() {
      let regex = /^[a-zA-Z.0-9]*$/;
      return regex.test(this.createNewDomain);
    }
  },
  methods: {
    toPage(data) {
      if (data === "prev") {
        if (this.currentPageDomain !== 1) {
          this.currentPageDomain = this.currentPageDomain - 1;
          this.getAllDomains();
        }
      } else {
        if (this.currentPageDomain !== this.lastPageDomain) {
          this.currentPageDomain = this.currentPageDomain + 1;
          this.getAllDomains();
        }
      }
    },
    toFirstDomain(id) {
      this.$root.$emit("loadOn");
      serviceDomain
        .postUnicID(id)
        .then(() => {
          this.getAllDomains();
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    deletarDomain(id) {
      this.$root.$emit("loadOn");
      serviceDomain
        .destroy(id)
        .then(() => {
          this.getAllDomains();
          this.$root.$emit("loadOff");
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    openNew() {
      this.$root.$emit("bv::hide::modal", "modal-dominio", "#btnShow");
      this.openNewDomain = true;
    },
    fecharModalDomain() {
      this.openNewDomain = false;
      this.closeNew();
      this.getAllDomains();
    },
    createDomain() {
      if (!this.domainValidation) {
        notify("erro", "O domínio não pode conter caractéres especiais!");
        return;
      }
      let data = {
        domain: this.createNewDomain,
      };
      this.$root.$emit("loadOn");
      serviceDomain
        .create(data)
        .then(() => {
          this.pageDomain = 2;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }

          this.$root.$emit("loadOff");
        });
    },
    getAllDomains() {
      // if(this.currentPageDomain !== 1){
      //   this.getAllDomains2();
      // }
      this.$root.$emit("loadOn");
      serviceDomain
        .search("page=" + this.currentPageDomain)
        .then((resp) => {
          //console.log("get all domain", resp);
          this.AllDominios = resp.data;
          this.totalDomain = resp.total;
          this.currentPageDomain = resp.current_page;
          this.lastPageDomain = resp.last_page;
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    closeNew() {
      this.$root.$emit("bv::show::modal", "modal-dominio", "#btnShow");
      this.openNewDomain = false;
    },
    getAllDomains2() {
      this.$root.$emit("loadOn");
      serviceDomain
        .search("page=1")
        .then((resp) => {
          if (resp.data.length === 1 || resp.data.length === 0) {
            this.AllDominiosFirst = resp.data;
          } else {
            this.AllDominiosFirst = resp.data.shift();
          }
        })
        .finally(() => {
          this.$root.$emit("loadOff");
        });
    },
    salvarModalConfigDominio() {
      this.$root.$emit("bv::hide::modal", "modal-dominio", "#btnShow");
    },
  },
};
</script>

<style lang="scss" scoped>
.allSpaceModalDominioFundo {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--fundomodal);
  z-index: 99999999;
}

.flexbtnConfigDominio {
  display: flex;
  justify-content: space-between;
  border-bottom: 0.5px solid #ededf0;
  padding-bottom: 25px;
}

.allSpaceListDomain {
  padding: 30px;
}

.allSpaceModalDominio {
  position: fixed;
  z-index: 9999999999;
  background-color: var(--backgroundcolor);
  top: 0;
  right: 0;
  height: 100%;
  width: 450px;
  padding: 50px;
  border-radius: 5px 0px 0px 5px;
  overflow: auto;
}

.allSpaceModalDominio::-webkit-scrollbar {
  background-color: #fff;
  width: 3px;
}

.allSpaceModalDominio::-webkit-scrollbar-track {
  background-color: #c7c7c7;
}

.allSpaceModalDominio::-webkit-scrollbar-thumb {
  background-color: #4d4d4d52;
  border-radius: 31px;
}

.allSpaceModalDominio::-webkit-scrollbar-button {
  display: none;
}

.titleModalDominio {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  color: var(--fontcolor);
}

.titleModalDominio2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: 0.2px;
  color: #81858e;
  margin-top: -10px;
}

.spaceClose {
  display: flex;
  justify-content: flex-end;
  svg {
    cursor: pointer;
    filter: var(--filtericon);
  }
}

.inputModalDominio {
  height: 55px;
  border: solid 1px var(--bordercolor);
  box-sizing: border-box;
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: #000000;
  z-index: 99999999999999999999999999;
  position: relative;
}

.hide {
  display: none;
}

.flexConfigDominioSub {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.textStatusModalDominio {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.spaceBtnModalDominio {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
}

.spaceInputModalDominio {
  margin-top: 50px;
}

.inputModalDominio:hover {
  border: 1px solid #00e4a0;
  font-weight: 600;
}

.subtitleModalDominio {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--fontcolor2);
  margin-top: 30px;
}

.subtitleModalDominio2 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: #81858e;
  margin-top: 30px;
}

.flexStatus {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
}

.btnConfigDominio2 {
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  height: 45px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #ffffff;
  width: 100%;
}

.btnConfigDominio {
  background: var(--maincolor);
  border: 1px solid var(--maincolor);
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  height: 45px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #ffffff;
  width: 251.29px;
}

.subtitleTextDomain {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  color: var(--fontcolor);
}

.btnCancel {
  background: #f7f7f7;
  border: 1px solid #f7f7f7;
  color: #81858e;
  box-sizing: border-box;
  border-radius: 5px;
  height: 45px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  width: 100%;
}

.textDomain {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: var(--fontcolor2);
  margin-top: 30px;
}

.divColor {
  background: #8585854f;
  padding: 10px;
  border-radius: 3px;
  width: 105%;
  left: -12px;
  position: relative;
}

.textTitleBB {
  display: flex;
}

.textTitleBB::before {
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border: 2px solid var(--maincolor);
  background: var(--maincolortrans);
  display: block;
  margin-right: -5px;
  position: relative;
  top: 8px;
  left: -12px;
}

.pointerStatus {
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background-color: #00e4a0;
  animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
}

.text-color {
  color: var(--fontcolor) !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  padding-bottom: 25px;
}

.classFontSize {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: var(--fontcolor2);
  margin-top: -20px;
}

.textTitle::before {
  content: "";
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid var(--maincolor);
  background: var(--maincolortrans);
  display: block;
  margin-right: 10px;
  position: relative;
  top: 5px;
}

.btn-openModal {
  background: var(--maincolortrans);
  border: 2px dashed var(--maincolor);
  box-sizing: border-box;
  border-radius: 5px;
  height: 55px;
  cursor: pointer;
  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--maincolor) !important;
    display: flex;
    justify-content: center;
    margin-top: 17px;
  }
}

.itemPreview {
  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor2) !important;
  }
  input {
    border: solid 1px var(--bordercolor);
    box-sizing: border-box;
    border-radius: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #000000;
    height: 50px;
  }
  input:hover,
  input:focus {
    border: 1px solid #00e4a0;
  }
}

.configDados {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  gap: 10%;
  margin: 20px 0px;
  button:first-child {
    background: rgba(33, 51, 210, 0.1);
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    height: 50px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #2133d2;
    border: none;
  }
  button:last-child {
    background: #00e4a0;
    border: none;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    height: 50px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #fff;
  }
}

.spaceTable {
  margin-top: 30px;
  .header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr;
    margin-bottom: 25px;
    div {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
    }
  }
  .itens {
    .spaceItens {
      display: grid;
      grid-template-columns: 2fr 1fr 1fr 2fr 2fr;
      height: 40px;
      border-bottom: 0.5px solid #ededf0;
      margin: 15px 5px;
      .txt {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        letter-spacing: 0.2px;
        color: #81858e;
      }
      .actions {
        display: flex;
        .deletar {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #ff0c37;
          cursor: pointer;
        }
        .editar {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 13px;
          letter-spacing: 0.2px;
          color: #81858e;
          margin-right: 15px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>